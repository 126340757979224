import axios from "axios";
import { indonesia_payment, members } from "../../js/path";
import _ from "lodash";

const apiEndpoint = process.env.VUE_APP_INDONESIA_PAYMENT;

export default {
  data() {
    return {
      pageNo: null,
      submitted: false,
      status: false,
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: true,
      tableData: [],
      products: [],
      currentPage: 1,
      activeTab: "all",
      params: "",
      key: 0,
      fields: [
        {
          key: "id",
          label: "ID",
          sortable: true,
        },
        {
          key: "company_name",
          label: "Company Name",
        },
        {
          key: "code",
        },
        {
          key: "value",
        },
        {
          key: "usage",
        },
      ],
      form: {
        id: "",
        gift_company_id: "",
        code: "",
        coupon_count: "",
      },
      memberList: [],
      getCompanyList: [],
    };
  },
  methods: {
    fetchCompanyList: _.debounce(async function (query) {
      this.$store.commit("loader/updateStatus", true);
      this.getCompanyList = [];
      if (query != "" && query != null) {
        const url = indonesia_payment?.getGiftCompany;
        const data = await axios.get(`${apiEndpoint}${url}?q=${query}`);
        console.log(data.data.response, "data");
        if (data.status) {
          this.getCompanyList = data.data.response.data;
        }
      }
      this.$store.commit("loader/updateStatus", false);
    }, 1000),

    fetchMemberList: _.debounce(async function (query) {
      this.$store.commit("loader/updateStatus", false);
      this.memberList = [];
      if (query != "" && query != null) {
        this.$store.commit("loader/updateStatus", true);
        const url = members.getMembers;
        const data = await this.getRequest(url + "?search=" + query);
        if (data.status) {
          this.memberList = data.response.data.map((member) => ({
            member_id: member.id,
            fnameAndLname: `${member.fname} ${member.lname} - ${member.mobile_number}`,
          }));
        }
      }
      this.$store.commit("loader/updateStatus", false);
    }, 1000),

    disabledPastDates() {
      const currentDate = new Date();
      return currentDate.toISOString().slice(0, 10);
    },

    searchFor() {
      if (this.filter.length > 1) this.fetchData();
      else if (this.filter.length == 0) this.fetchData();
    },

    search(event) {
      if (this.filter.length > 1) {
        if (event.keyCode == 13) {
          this.fetchData();
        }
      } else if (this.filter.length == 0) this.fetchData();
    },

    filterPage() {
      if (this.pageNo.length > 0) {
        this.currentPage = this.pageNo;
      }
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    changestatus() {
      if (this.status) {
        this.status = false;
      } else {
        this.status = true;
      }
    },

    async fetchData(txt) {
      this.$store.commit("loader/updateStatus", true);
      if (txt == "active") {
        this.activeTab = txt;
      } else if (txt == "trash") {
        this.activeTab = txt;
      } else if (txt == "all") {
        this.activeTab = txt;
      }
      let request = `${indonesia_payment?.getGiftCard}?page=${
        this.currentPage ?? 1
      }&q=${this.filter ?? ""}&filter=${txt ?? ""}`;
      try {
        const res = await axios.get(`${apiEndpoint}${request}`);
        if (res) {
          this.tableData = res?.data?.response;
        }
        this.$store.commit("loader/updateStatus", false);
      } catch (err) {
        this.$store.commit("toast/updateStatus"),
          {
            status: true,
            icon: "error",
            title: "Somthing went wrong",
          };
        this.$store.commit("loader/updateStatus", false);
      }
    },

    async updateStatus(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let index = this.tableData.data.findIndex((e) => e.id === id);
        const url = `${apiEndpoint}${indonesia_payment?.updateGiftCard}/${id}`;
        const data = await axios.post(url, {
          is_active: !this.tableData.data[index].is_active,
        });
        if (data.status) {
          this.tableData.data[index].is_active =
            !this.tableData.data[index].is_active;
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: "Status updated successfully",
          });
        }
        this.key += 1;
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },

    async submitData() {
      try {
        let formData = new FormData();
        if (this.form.is_active) {
          formData.append("is_active", 1);
        } else {
          formData.append("is_active", 0);
        }
        if (this.form.user_reference_id) {
          formData.append("user_reference_type", "medisage");
          formData.append(
            "user_reference_id",
            this.form.user_reference_id.member_id ?? ""
          );
        }
        if (this.form.gift_company_id) {
          formData.append(
            "gift_company_id",
            this.form.gift_company_id.id ?? ""
          );
        }
        for (var key in this.form) {
          if (key == "id") continue;
          if (
            key != "is_active" &&
            key != "user_reference_id" &&
            key != "gift_company_id"
          ) {
            formData.append(key, this.form[key]);
          }
        }
        let url = `${apiEndpoint}${indonesia_payment?.getGiftCard}`;
        const request = await axios.post(url, formData);
        const response = request.data;
        if (response.status) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: response.message,
          });
          this.$router.push("/gift-card");
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.message ? err.message : "Please try again........!",
        });
        console.log(err, "err in catch");
      }
    },

    changeValidFrom() {
      this.form.valid_till = "";
    },

    async fetchGiftCompany(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        const request = `${indonesia_payment?.getGiftCard}/${id}`;
        const response = await axios.get(`${apiEndpoint}${request}`);
        const res = response?.data;
        console.log(res, "res");
        if (res) {
          const responseData = res.response;
          if (responseData.id !== "" && responseData.id !== null) {
            this.form.id = responseData.id;
          }
          if (responseData.name !== "" && responseData.name !== null) {
            this.form.name = responseData.name;
          }
          if (responseData.code !== "" && responseData.code !== null) {
            this.form.code = responseData.code;
          }
          if (responseData.usage !== "" && responseData.usage !== null) {
            this.form.usage = responseData.usage;
          }
          if (responseData.value !== "" && responseData.value !== null) {
            this.form.value = responseData.value;
          }
          if (responseData.company_name && responseData?.gift_company_id) {
            this.form.gift_company_id = {
              id: responseData?.gift_company_id,
              name: responseData?.company_name,
            };
          }
          if (responseData.is_active === 1) {
            this.form.is_active = true;
          } else {
            this.form.is_active = false;
          }
        }
        this.$store.commit("loader/updateStatus", false);
      } catch (err) {
        console.error("Error fetching payment gateway:", err);
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "Something went wrong",
        });
        this.$store.commit("loader/updateStatus", false);
      }
    },
  },

  watch: {
    currentPage: {
      handler: function (v) {
        if (v != "" && v != null) {
          this.fetchData(this.activeTab);
          this.pageNo = this.currentPage;
        }
      },
    },
  },
  created() {
    if (
      this.$route.name == "add-gift-card" ||
      this.$route.name == "edit-gift-card"
    ) {
      if (this.$route.name == "edit-gift-card") {
        this.fetchGiftCompany(this.$route.params.id);
      }
    } else {
      this.fetchData();
    }
  },
};
